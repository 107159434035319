"use strict";

import AOS from "./aos";
//Animate on-scroll
AOS.init({
  once: true
});

var targets = document.querySelectorAll("[data-target]");

function targetClickHandler(e) {
  var target = document.getElementById(this.getAttribute("data-target"));
  this.classList.toggle("is-active");
  if (target.classList.contains("is-active")) {
    target.classList.remove("is-active");
  } else {
    target.classList.add("is-active");
  }
}

Array.prototype.forEach.call(targets, function(target, i) {
  target.addEventListener("click", targetClickHandler);
});

function hasClass(el, className) {
  return el.className.indexOf(className) >= 0;
}
function toggleClass(el, className) {
  if (hasClass(el, className)) {
    el.className = el.className.replace(className, "");
  } else {
    el.className = el.className.trim() + " " + className;
  }
}

function toggleText(el, options = {}) {
  //Default parameters
  options = Object.assign(
    {
      active: "",
      deactive: "",
      className: "",
      target: null
    },
    options
  );

  var target = options.target,
    active = options.active,
    className = options.className,
    deactive = options.deactive;

  if (hasClass(el, className)) {
    target.className = target.className.replace(
      /icon-down-open/,
      "icon-up-open"
    );
    return;
  }

  target.className = target.className.replace(/icon-up-open/, "icon-down-open");
}

//Collapse
var collapses = document.querySelectorAll(".collapse-card");

Array.prototype.forEach.call(collapses, function(collapse) {
  var title = collapse.getElementsByClassName("collapse-title")[0];

  title.addEventListener("click", function(e) {
    var collapseBtn = collapse.getElementsByClassName("collapse-btn")[0];
    //collapse.classList.toggle("is-active");
    toggleClass(collapse, "is-active");
    toggleText(collapse, {
      active: "+",
      deactive: "-",
      target: collapseBtn,
      className: "is-active"
    });
  });
});

var acModule = document.querySelector(".ac-modules");
var acModules = acModule.querySelectorAll('input[type="checkbox"]');
var acSelectAll = acModule.querySelector(".select-all");
var acDeselectAll = acModule.querySelector(".deselect-all");

var boModule = document.querySelector(".bo-modules");
var boModules = boModule.querySelectorAll('input[type="checkbox"]');
var boSelectAll = boModule.querySelector(".select-all");
var boDeselectAll = boModule.querySelector(".deselect-all");

var checkAllInput = function(modules, e) {
  console.log(e);
  e.preventDefault();
  modules.forEach(function(select) {
    select.checked = true;
  });
};

var uncheckAllInput = function(modules, e) {
  e.preventDefault();
  modules.forEach(function(select) {
    select.checked = false;
  });
};

acSelectAll.addEventListener("click", checkAllInput.bind(null, acModules));
acDeselectAll.addEventListener("click", uncheckAllInput.bind(null, acModules));

boSelectAll.addEventListener("click", checkAllInput.bind(null, boModules));
boDeselectAll.addEventListener("click", uncheckAllInput.bind(null, boModules));

function toggleSelect(parent, inputs) {}
